body {
  overflow-x: hidden;
}

.container {
  width: 100% !important;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.sticky {
  background-color: #1b1f24 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
  backdrop-filter: blur(5px) !important;
}

.navbar {
  background-color: #1b1f24 !important;
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
  overflow-x: hidden; /* Hide horizontal overflow */
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #ffffff !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

.navbar-nav .nav-item {
  position: relative;
}

.navbar-nav .nav-item:not(:first-child) {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 4px;
  width: 0;
  border-radius: 16px;
  background: #ffffff;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .navbar {
    background-color: #1b1f24 !important;
    font-size: 1.4rem !important;
    padding: 1rem 2rem !important;
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }

  .navbar-nav .nav-item {
    margin-left: 0 !important;
  }
}

.exp-section {
  position: relative;
  background-color: #1b1f24;
  color: #fff;
}

.exp-content {
  padding: 9rem 0 3rem !important;
  color: whitesmoke;
  text-align: left;
}

.exp-header {
  display: block;
  margin-bottom: 5rem;
  text-align: center;
}

.exp-header h1 {
  margin-bottom: 1rem;
  border-bottom: 2px solid #fff;
  display: inline-block;
  padding-bottom: 0.1rem;
}

.company-block {
  align-items: center;
  margin-bottom: 7rem;
}

.card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
}

.company-logo {
  display: block;
  text-align: center;
  height: 280px;
}

.company-logo img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.company-info-left {
  display: block;
  margin-left: 7rem;
}

.company-info .line {
  display: flex;
  width: 10%;
  border-top: .125rem solid #ffffff;
  margin: 1rem 0 2rem;
}

.company-info-left .line {
  display: flex;
  width: 10%;
  border-top: .125rem solid #ffffff;
  margin: 1rem 0 2rem;
}

.company-info ul {
  list-style-type:disc;
  padding-left: 1rem;
}

.company-info-left ul {
  list-style-type:disc;
  padding-left: 1rem;
}

.company-info-left a {
  font-size: 2rem;
  transition: color 0.3s, transform 0.3s;
}

.company-info-left .github-icon:hover {
  transform: scale(1.2);
}

.company-info-left .icons {
  color: white;
}

.company-info-left .icons:hover {
  color: rgb(193, 193, 193);
}

.company-info a {
  font-size: 2rem;
  transition: color 0.3s, transform 0.3s;
}

.company-info .github-icon:hover {
  transform: scale(1.2);
}

.company-info .icons {
  color: white;
}

.company-info .icons:hover {
  color: rgb(193, 193, 193);
}

@media screen and (max-width: 1399px) {
  .company-info-left {
    margin-left: 0;
  }

  .company-logo {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 991px) {
  .company-block {
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .company-block-reverse {
    flex-direction: column-reverse;
  }

  .company-info-left {
    margin-left: 0;
    width: 100%;
  }

  .company-info {
    width: 100%;
  }

  .col-md-5 {
    width: 80%;
  }

  .company-logo {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .exp-section {
    padding: 0rem 2rem !important;
  }

  .exp-content {
    padding: 6rem 0 1rem !important;
  }

  .exp-header {
    margin-bottom: 3rem;
  }

  .company-info-left {
    margin-left: 0;
  }

  .col-md-5 {
    width: 100%;
  }

  .company-logo {
    margin-bottom: 2rem;
    width: 100%;
    height: auto;
  }
}